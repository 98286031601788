import { Autoplay, Navigation, Pagination, Swiper, Thumbs } from "swiper";
import AutoplayPauseOnFocus from "./swiper/AutoplayPauseOnFocus";
import FixTabIndex from "./swiper/FixTabIndex";
import LazyImages from "./swiper/LazyImages";
const swiperDefaults = {
    speed: 500,
};
export default function initSliders() {
    makeBannersSlider();
    makeSwiper('.brands-slider', [Autoplay, Pagination, Navigation], { loop: true });
    makeSwiper('.reviews-slider', [Autoplay, Navigation, LazyImages], { loop: true });
    makeProdCarouselSwiper();
}
export function makeProductThumbsSlider() {
    return makeSwiper('.prod-pager', [Navigation, LazyImages], {
        slidesPerView: 3,
        shortSwipes: false,
        on: {
            init(swiper) {
                if (swiper.slides.length <= 3) {
                    swiper.$el.addClass('swiper-centered');
                }
            },
        },
    });
}
export function makeProductSlider(thumbsSwiper) {
    return makeSwiper('.prod-slider', [Thumbs, LazyImages], {
        thumbs: {
            swiper: thumbsSwiper,
            autoScrollOffset: 0,
        },
    });
}
export function makeBannersSlider() {
    const interleaveOffset = 0.45;
    return makeSwiper('.banners-slider', [Autoplay, Pagination, Navigation, LazyImages], {
        loop: true,
        slidesPerView: 'auto',
        speed: 1000,
        pagination: {
            el: document.querySelector('.banners-slider+.swiper-pagination'),
        },
        on: {
            progress: function (swiper) {
                for (let i = 0; i < swiper.slides.length; i++) {
                    const innerOffset = swiper.width * interleaveOffset;
                    // @ts-ignore
                    const innerTranslate = swiper.slides[i].progress * innerOffset;
                    swiper.slides[i].firstElementChild.style.transform = `translateX(${innerTranslate}px)`;
                }
            },
            touchStart: function (swiper) {
                for (let i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[i].style.transition = "";
                }
            },
            setTransition: function (swiper, speed) {
                for (let i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[i].style.transition = `${speed}ms`;
                    swiper.slides[i].firstElementChild.style.transition = `${speed}ms`;
                }
            }
        }
    });
}
export function makeTagsSlider() {
    return makeSwiper('.tags-slider', [Pagination], { loop: false });
}
export function makeSimilarProductsCarousel() {
    return makeSwiper('.same-prods__carousel', [Navigation, LazyImages], {
        slidesPerGroup: 1,
        slidesPerView: 1,
        breakpoints: {
            350: {
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            720: {
                slidesPerGroup: 3,
                slidesPerView: 3,
            },
            900: {
                slidesPerGroup: 4,
                slidesPerView: 4,
            },
        },
        breakpointsBase: 'container',
    });
}
export function makeProdCarouselSwiper() {
    makeSwiper('.prod-carousel', [Navigation, LazyImages], {
        slidesPerGroup: 1,
        slidesPerView: 1,
        breakpoints: {
            350: {
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            720: {
                slidesPerGroup: 3,
                slidesPerView: 3,
            },
            940: {
                slidesPerGroup: 4,
                slidesPerView: 4,
            },
            1140: {
                slidesPerGroup: 5,
                slidesPerView: 5,
            }
        },
        breakpointsBase: 'container',
    });
}
function makeSwiper(container, modules = [], options = {}) {
    const builder = getOptions(options);
    for (const module of modules) {
        if (module === Autoplay) {
            builder.addAutoplay();
        }
        else if (module === Pagination) {
            builder.addPagination();
        }
        else if (module === Navigation) {
            builder.addNavigation();
        }
        else {
            builder.addModule(module);
        }
    }
    return new Swiper(container, builder.getOptions());
}
function getOptions(options) {
    if (options) {
        options = Object.assign(Object.assign({}, swiperDefaults), options);
    }
    else {
        options = Object.assign({}, swiperDefaults);
    }
    if (!options.modules) {
        options.modules = [];
    }
    const addModule = (module) => {
        options.modules.push(module);
        return this;
    };
    const addAutoplay = () => {
        addModule(Autoplay);
        addModule(AutoplayPauseOnFocus);
        options = Object.assign(Object.assign({}, options), { autoplay: {
                delay: 5000,
                pauseOnMouseEnter: false,
                disableOnInteraction: true,
            } });
        return this;
    };
    const addNavigation = () => {
        addModule(Navigation);
        options.navigation = {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        };
        return this;
    };
    const addPagination = () => {
        addModule(Pagination);
        options.pagination = Object.assign({ el: '.swiper-pagination', clickable: true, renderBullet: (index, className) => `<button class="${className}"></button>` }, options.pagination);
        return this;
    };
    addModule(FixTabIndex);
    const getOptions = () => options;
    return {
        addModule,
        addAutoplay,
        addNavigation,
        addPagination,
        getOptions,
    };
}
