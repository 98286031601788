import LazyLoad from "vanilla-lazyload";
import { lazyConfig } from "../lazy";
const IMG_SELECTOR = 'picture.lazy:not(.lazy--loaded) img:not(.loading)';
export default function LazyImages({ swiper, on, off }) {
    on('init', swiper => {
        // задержка нужна, чтобы не происходило отмены загрузки картинок
        setTimeout(() => check(swiper), 100);
    });
    on('slideChangeTransitionStart', check);
    on('slideChangeTransitionEnd', check);
    function check(swiper) {
        const slidesPerView = swiper.params.slidesPerView === 'auto' ? 1 : swiper.params.slidesPerView;
        const activeIndex = swiper.activeIndex;
        const totalSlides = getTotalSlidesNumber();
        let updated = preloadImages(activeIndex + slidesPerView, activeIndex + slidesPerView + slidesPerView);
        if (!swiper.loopedSlides) {
            return;
        }
        const startIndex = (swiper.realIndex < slidesPerView ? totalSlides + swiper.realIndex - slidesPerView : swiper.realIndex - slidesPerView);
        for (let i = startIndex; i < startIndex + slidesPerView; i++) {
            swiper.$wrapperEl.find(`.${swiper.params.slideClass}[data-swiper-slide-index="${i}"] ${IMG_SELECTOR}`).each(image => {
                if (preloadImage(image)) {
                    updated = true;
                }
            });
        }
        if (updated) {
            return;
        }
        if (swiper.$wrapperEl.find(IMG_SELECTOR).length) {
            return;
        }
        off('init', check);
        off('slideChangeTransitionStart', check);
        off('slideChangeTransitionEnd', check);
    }
    function preloadImages(start, end) {
        let updated = false;
        for (let i = start; i < end; i++) {
            if (!swiper.slides[i]) {
                break;
            }
            if (preloadImage(swiper.slides[i].querySelector(IMG_SELECTOR))) {
                updated = true;
            }
        }
        return updated;
    }
    function preloadImage(image) {
        if (!image) {
            return false;
        }
        LazyLoad.load(image, lazyConfig);
        return true;
    }
    function getTotalSlidesNumber() {
        if (!swiper.loopedSlides) {
            return swiper.slides.length;
        }
        return swiper.$wrapperEl.find(`.${swiper.params.slideClass}:not(.${swiper.params.slideDuplicateClass})`).length;
    }
}
