export default function AutoplayPauseOnFocus({ on }) {
    let focused = false;
    let hovered = false;
    on('afterInit', swiper => {
        if (!swiper.params.autoplay) {
            return;
        }
        swiper.$el
            .on('mouseenter', () => {
            hovered = true;
            fixAutoplay(swiper);
        })
            .on('mouseleave', () => {
            hovered = false;
            fixAutoplay(swiper);
        });
        swiper.$el.find('a, button')
            .on('focus', () => {
            focused = true;
            fixAutoplay(swiper);
        })
            .on('blur', () => {
            focused = false;
            fixAutoplay(swiper);
        });
    });
    function fixAutoplay(swiper) {
        if (!swiper.autoplay.running) {
            return;
        }
        if ((hovered || focused) && !swiper.autoplay.paused) {
            swiper.autoplay.pause();
        }
        else if (!(hovered || focused) && swiper.autoplay.paused) {
            swiper.autoplay.paused = false;
            swiper.autoplay.run();
        }
    }
}
