import $ from "../../vendor/dom7";
export default function FixTabIndex({ swiper, on }) {
    on('beforeInit', () => {
        swiper.params.watchSlidesProgress = true;
    });
    on('init', fixTabIndex);
    on('slideChangeTransitionStart', fixTabIndex);
    function fixTabIndex(swiper) {
        swiper.slides.forEach((slide) => {
            if (slide.matches('.swiper-slide-visible')) {
                $(slide).find('[tabindex="-1"]')
                    .attr('tabindex', '');
            }
            else {
                $(slide).find('a, button, input, select, textarea, details')
                    .filter((element) => element.tabIndex !== -1)
                    .attr('tabindex', "-1");
            }
        });
    }
}
