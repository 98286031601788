import initSliders, { makeProdCarouselSwiper } from "./components/slider";
import $ from "./vendor/dom7";
import { hidePreloader, showPreloader } from "./components/preloader";
import { getFetchOptions } from "./utils";
import initLazyLoad from "./components/lazy";
initSliders();
$(document).on('click', '.item_slider', function () {
    const td = this;
    const $container = $(this).parents('.item_slider_div');
    $('.item_slider').removeClass('tab-link--active');
    td.classList.add('tab-link--active');
    showPreloader($container[0]);
    fetch('/ajax/slider', getFetchOptions({ type: this.dataset.type }, true))
        .then(response => response.text())
        .then(html => {
        $container.html(html);
        initLazyLoad();
        makeProdCarouselSwiper();
        hidePreloader(true);
    });
});
